import './EproPublic.less'

import { useLocation } from '@gatsbyjs/reach-router'
import { Form, Input } from 'antd'
import classNames from 'classnames'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useEffect, useState } from 'react'
import Div100vh from 'react-div-100vh'

import SurveyUnavailable from '../../../../../assets/images/survey-unavailable.svg'
import { useScopedIntl } from '../../../../../hooks'
import { AccountType, createEproPublicRecord, fetchPublicEproLandingPage } from '../../../../../requests'
import { routes } from '../../../../../routes'
import { proxyMinioToLocalhost } from '../../../../../utils'
import { validateEmail, validateRequired } from '../../../../../validation'
import { FormSubmitButton } from '../../../../auth/FormSubmitButton'
import { TermsControl } from '../../../../auth/SignUpForm'
import {
  DatacFormItem,
  DatacIcon,
  DatacLanguageSelector,
  DatacLoading,
  DatacMessage,
  DatacSubtitle,
  DatacTitle
} from '../../../../common'

const ExpiredEproPage: React.FC = () => {
  const eproSurveyIntl = useScopedIntl('studies.epro.survey.expired')
  return (
    <div className="epro-landing-page-expired">
      <SurveyUnavailable />
      <p>{eproSurveyIntl('label')}</p>
      <p>{eproSurveyIntl('content')}</p>
    </div>
  )
}

export const EproPublic: React.FC = () => {
  const intl = useScopedIntl('')
  const eproPublicIntl = useScopedIntl('studies.epro.public')
  const eproInviteIntl = useScopedIntl(`studies.epro.invite_subjects_modal`)
  const location = useLocation()
  const token = location.hash.slice(1)
  const [loading, setLoading] = useState(true)
  const [isExpired, setIsExpired] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [emailRequired, setEmailRequired] = useState(false)
  const [error, setError] = useState<string>(undefined)
  const [logoUrl, setLogoUrl] = useState('')
  const [isSubmitOngoing, setIsSubmitOngoing] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [formInstance] = Form.useForm()

  useEffect(() => {
    if (!token) {
      navigate(routes.notFound(AccountType.Subject))
      return
    }

    setLoading(true)
    fetchPublicEproLandingPage(
      { token },
      {
        onSuccess: ({ emailRequired, title, description, logoUrl }) => {
          setTitle(title)
          setDescription(description)
          setEmailRequired(emailRequired)
          setLogoUrl(logoUrl)
          setLoading(false)
        },
        onNotFound: () => navigate(routes.notFound(AccountType.Subject)),
        onExpired: () => setIsExpired(true),
        onQrIsOff: () => DatacMessage.error(eproPublicIntl('error.title'), eproPublicIntl('error.qr_is_off')),
        onRequestError: code => DatacMessage.genericError(intl, code),
        onError: () => setLoading(false)
      }
    )
  }, [token])

  const onFormSubmit = ({ email }: { email: string }) => {
    setIsSubmitOngoing(true)
    createEproPublicRecord(
      {
        token,
        email: emailRequired ? email : undefined,
        messageSubject: eproInviteIntl('email_subject.default', { surveyName: '' }),
        messageMessage: eproInviteIntl('email_body.default')
      },
      {
        onSuccess: token => {
          if (emailRequired) setShowSuccessMessage(true)
          else navigate(routes.eproSurvey(token))
        },
        onAlreadyExists: () => setError(eproPublicIntl('email.validation.already_existing')),
        onQrIsOff: () => DatacMessage.error(eproPublicIntl('error.title'), eproPublicIntl('error.qr_is_off')),
        onSubjectHasOtherCenter: () => setError(eproPublicIntl('error.subject_has_other_center')),
        onRequestError: code => DatacMessage.genericError(intl, code),
        onError: () => setIsSubmitOngoing(false)
      }
    )
  }

  const clearError = () => error && setError(undefined)

  return (
    <Div100vh id="100vh-div">
      <div className="epro-public" key={token}>
        <DatacLoading isLoading={loading}>
          {isExpired ? (
            <ExpiredEproPage />
          ) : (
            <>
              <div className="epro-public__left">
                <div className="epro-public__left__header">
                  {emailRequired && <div className="epro-public__left__background" />}
                  <div
                    className={classNames('epro-public__left__wrapper', {
                      'epro-public__left__header--with_background': emailRequired
                    })}
                  >
                    <div className="epro-public__left__header__top">
                      {logoUrl ? (
                        <img
                          src={proxyMinioToLocalhost(logoUrl)}
                          alt="logo"
                          className="epro-public__left__header__logo"
                        />
                      ) : (
                        <>
                          <DatacIcon raw name="datac" className="epro-public__left__header__top__logo" />
                          <DatacTitle>datacapt</DatacTitle>
                        </>
                      )}
                      <DatacLanguageSelector />
                    </div>
                    <DatacTitle type="h1" className="epro-public__left__header__title">
                      {title}
                    </DatacTitle>
                    <DatacSubtitle type="h2" className="epro-public__left__header__subtitle">
                      {description}
                    </DatacSubtitle>
                  </div>
                </div>
                <div className="epro-public__left__content">
                  {!emailRequired && <div className="epro-public__left__background" />}
                  {showSuccessMessage ? (
                    <div className="epro-public__left__content__success">{eproPublicIntl('success.message')}</div>
                  ) : (
                    <Form
                      form={formInstance}
                      name="eproPublicForm"
                      onFinish={onFormSubmit}
                      className={classNames('epro-public__left__wrapper', {
                        'epro-public__left__content--with_background': !emailRequired
                      })}
                    >
                      {emailRequired && (
                        <>
                          <DatacFormItem
                            name="email"
                            label={eproPublicIntl('email.label')}
                            validate={[
                              validateRequired(intl('common.required')),
                              validateEmail(eproPublicIntl('email.validation.email'))
                            ]}
                            error={error}
                          >
                            <Input className="auth-form-input" size="large" onChange={clearError} />
                          </DatacFormItem>
                          <DatacFormItem
                            className="signup-form__terms-form-input"
                            name="terms"
                            validate={validateRequired(intl('common.required'))}
                          >
                            <TermsControl accountType={AccountType.Subject} />
                          </DatacFormItem>
                        </>
                      )}

                      <Form.Item>
                        <FormSubmitButton label={eproPublicIntl('submit')} loading={isSubmitOngoing} />
                      </Form.Item>
                    </Form>
                  )}
                </div>
              </div>
              <div className="epro-public__right" />
            </>
          )}
        </DatacLoading>
      </div>
    </Div100vh>
  )
}
